import CatSvg from '@/assets/cat.svg'
import { searchParamsSearch } from '@/lib/searchParams'
import { useQueryStates } from 'nuqs'

export const NoResults = () => {
  const [searchParams] = useQueryStates(searchParamsSearch)
  return <div className='w-fit mx-auto'>
    <CatSvg className='w-64 mx-auto' />
    <p><strong>0 results</strong> for &quot;{searchParams.searchQuery}&quot;</p>
    <h4 className='font-medium'>Suggestions</h4>
    <ul className='text-left list-disc pl-6'>
      <li>Make sure words are spelled correctly</li>
      <li>Try different or more general keywords</li>
    </ul>
  </div>
}