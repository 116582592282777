import classNames from "classnames"
import { ReactNode } from "react"

export const InfoBubble = ({children, pointer = 'right', className = undefined}:{
  children: ReactNode
  pointer?: 'above' | 'below' | 'left' | 'right'
  className?: string
}) => {
  return <div className={classNames(
    "relative rounded-lg bg-black/80 text-white p-2 font-main font-light",
    "before:content-[''] before:absolute before:bg-black/80",
    pointerClasses(pointer),
    className
  )}>{children}</div>
}

function pointerClasses(pointer: 'above' | 'below' | 'left' | 'right') {
  switch (pointer) {
    case 'above':
      return 'before:mt-6 before:w-6 before:h-3 before:bottom-full before:left-[calc(50%_-_2.25rem_/_2)] before:[clip-path:polygon(12.5%_100%,_50%_0,_87.5%_100%)]'
    case 'below':
      return 'before:mb-6 before:w-6 before:h-3 before:top-full before:left-[calc(50%_-_2.25rem_/_2)] before:[clip-path:polygon(12.5%_0,_87.5%_0,_50%_100%)]'
    case 'left':
      return 'before:ml-6 before:w-1.5 before:h-4 before:right-[99%] before:top-[calc(33%)] before:[clip-path:polygon(0_50%,_100%_12.5%,_100%_87.5%)]'
    case 'right':
      return 'before:mr-6 before:w-3 before:h-6 before:left-full before:top-[calc(50%_-_2.25rem_/_2)] before:[clip-path:polygon(0_12.5%,_100%_50%,_0_87.5%)]'
  }
}