import { DrupalTaxonomyTermProduct } from "@/types"
import classNames from "classnames"
import { HTMLProps } from "react"
import { ButtonWithOptions, Link } from "../base/Button"
import SvgSupportWhite from "@/dist/SupportWhite"

export const DEFAULT_SUPPORT_CENTER_URL = 'https://support-acquia.force.com/s/contactsupport'

interface ProductTerm extends Pick<DrupalTaxonomyTermProduct, 'id' | 'name' | 'field_support_center_url'> {

}

export const SupportCTA = ({supportUrls, onClick, primary = true}: {
  supportUrls?: ProductTerm[]
  onClick?: HTMLProps<HTMLAnchorElement>['onClick']
  primary?: boolean
}) => {
  if (supportUrls && supportUrls.length == 1) {
    return <SupportLink target="_blank" primary={primary} onClick={onClick} href={supportUrls[0].field_support_center_url.uri ?? DEFAULT_SUPPORT_CENTER_URL}>{supportUrls[0].name} Support</SupportLink>
  }
  if (supportUrls && supportUrls.length > 1) {
    return <SupportLinkWithOptions primary={primary} href={DEFAULT_SUPPORT_CENTER_URL} onClick={onClick} supportUrls={supportUrls} />
  }
  return <SupportLink target="_blank" className="after:hidden" primary={primary} onClick={onClick} href={DEFAULT_SUPPORT_CENTER_URL}>Acquia Support</SupportLink>
}

function SupportLinkWithOptions({href, supportUrls, onClick, primary}: {
  href: HTMLProps<HTMLAnchorElement>["href"]
  supportUrls: ProductTerm[]
  onClick?: HTMLProps<HTMLAnchorElement>['onClick']
  primary: boolean
}) {

  return <ButtonWithOptions
        subtitle='Other support centers'
        color='blue'
        primaryOption={<MenuLink onClick={onClick} href={href}>Acquia Support</MenuLink>}
        primary={primary}
        options={supportUrls
          .filter(product => product.field_support_center_url.uri != href)
          .map(product => <ProductMenuLink key={product.id} onClick={onClick} product={product} />)}
        >
        <SvgSupportWhite className='w-4' />
        Contact support
      </ButtonWithOptions>
}

function SupportLink({children, primary, ...props}: HTMLProps<HTMLAnchorElement> & {
  primary: boolean
}) {
  return <Link primary={primary} {...props} color="blue">
      <span className="flex gap-2 items-center">
        <SvgSupportWhite className='w-4' /> {children}
      </span>
    </Link>
}

function MenuLink({children, className, ...props}: HTMLProps<HTMLAnchorElement>) {
  return <a 
  target="_blank"
  className={classNames("flex flex-grow gap-2 items-center after:hidden whitespace-nowrap p-3", className)}
  {...props}
  >
    {children}
  </a>
}

function ProductMenuLink({product, ...props}: {
  product: ProductTerm
} & HTMLProps<HTMLAnchorElement>) {
  return <MenuLink href={product.field_support_center_url.uri} {...props}>{product.name}</MenuLink>
}