'use client'

import React, { ReactNode, useEffect, useState } from "react"
import { GenerativeResponse, getGenerativeResponse } from "../../../lib/search/openai-server-actions.server";
import Image from "next/image";
import { Body } from "@/components/wysiwyg/Body";
import { parse } from 'marked';
import { searchType } from "./SearchResults";
import { Info } from "@/components/widget/info";
import Link from "next/link";
import OpenAI from "openai";
import { useContentFromRoute } from "@/contexts/ContentRouteProvider";
import { useQueryStates } from "nuqs";
import { searchParamsSearch } from "@/lib/searchParams";
import { DrupalNode, DrupalTaxonomyTermProduct } from "@/types";
import { SearchHelp } from "./SearchHelp.client";

export const OpenAISearchResults = ({type}: {
  type: searchType
}) => {

    const [response, setResponse] = useState<ReactNode[]>()
    const [references, setReferences] = useState<GenerativeResponse['references']>()
    const [isWorking, setIsWorking] = useState(false)
    const { resource } = useContentFromRoute<DrupalNode>()
    const [searchParams] = useQueryStates(searchParamsSearch)

    let searchString = searchParams.searchQuery ?? ''

    if (type === 'aisearch' && resource) {
      const products:DrupalTaxonomyTermProduct[] = resource.field_products ? resource.field_products : [resource.field_product];
      searchString += ` category:${products.join('|')}`
    }

    useEffect(() => {
        if (searchParams.searchQuery == '' || searchParams.searchQuery === null || !type) {
            return
        }

        setIsWorking(true)

        getGenerativeResponse(searchString, type).then((response) => {
          setReferences(response.references)
          const components = response.content.map((content, index):ReactNode => {
            switch (content.type) {
              case 'text':
                const textValue = replaceCitations(content.text.value, content.text.annotations, response.references)
                return <Body value={parse(textValue, {async: false}) as string} key={`text-result-${index}`} />
              case 'image_url':
                return <Image src={content.image_url.url} alt={content.image_url.detail ?? 'image'} key={`image-result-${index}`} />
              default:
                console.warn(`Missing content type support for ${content.type}`, content)
                return <></>
            }
          })
          setResponse(components)
          setIsWorking(false)
        })

        // Prevent further searches to protect the API from search stampedes.
    }, [searchParams.searchQuery, type, searchString])

    if (isWorking) {
      return <SearchHelp isWorking={isWorking} hasResults={false} />
    }

    if (searchParams.searchQuery == '' || searchParams.searchQuery === null || response === undefined) {
      return <></>
    }

    return <>
      {(isWorking || response.length > 0) && (
        <div className="px-4 bg-yellow-300 rounded-lg text-sm flex">
          <div className="pt-3 pr-2"><Info>Using OpenAI GPT-4o</Info></div>
          <p>This uses AI generated content optimised with content from docs.acquia.com.</p>
        </div>
      )}
      {!isWorking && searchParams.searchQuery !== undefined && response.length > 0 && (
        <>
          {response}
          {references && Object.keys(references).length > 0 && (
            <>
            <h4 className="font-medium mt-8">References</h4>
            <ol className="ml-4 pl-4 list-decimal mb-8">
              {Object.keys(references).filter(k => references[k].path).map(k => (
                <li key={k} className="my-1">
                  <Link className="text-blue-600 underline" href={references[k].path[0].alias}>{references[k].label}</Link>
                </li>
              ))}
            </ol>
            </>
          )}
        </>
      )}
    </>
}

function replaceCitations(content:string, annotations: OpenAI.Beta.Threads.Messages.Annotation[], references: GenerativeResponse['references']) {
  return annotations.reduce((p, c) => {
    if (c.type != 'file_citation') {
      return p
    }
    const i = Object.keys(references).indexOf(c.file_citation.file_id) + 1
    const reference = references[c.file_citation.file_id] ?? undefined
    if (!reference) {
      return p.replace(c.text, '')
    }
    return p.replace(c.text, `<sup><a class="underline mx-0.5" href="${reference.path[0].alias}" title="${reference.label}">${i}</a></sup>`)
  }, content)
}