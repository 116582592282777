'use client'

import { SearchResults, searchType } from "@/components/app/search/SearchResults";
import { useSearchUi } from "@/contexts/SearchUiProvider";
import { CheckIcon, Cog6ToothIcon, XMarkIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import React, { useEffect, useId, useRef, useState } from "react"
import { Menu } from '@headlessui/react'
import { DrupalNode } from "@/types";
import AiSvg from "@/assets/ai.svg"
import Droplet from "@/public/assets/logo-droplet.svg"
import { useContentFromRoute } from "@/contexts/ContentRouteProvider";
import { ProductFilters } from "./ProductFilters.client";
import { useProducts } from "@/contexts/ProductsProvider";
import { useQueryStates } from "nuqs";
import { searchParamsSearch } from "@/lib/searchParams";
import { SearchHelp } from "./SearchHelp.client";
import { SearchQuestions } from "./SearchQuestions.client";

export const SidebarSearch = ({accessConfiguration = false}: {
  accessConfiguration: boolean
}) => {
  const { isVisible, hideSearchUi, showSearchUi, runSearch, current } = useSearchUi();
  const [helpEngine, setHelpEngine] = useState<searchType>('search')
  const observer = useRef<IntersectionObserver>()
  const inputRef = useRef<HTMLInputElement>(null)
  const stickyId = useId()
  const observerMarkId = useId()
  const { products } = useProducts()
  const productFilters = products.filter(p => current?.products?.includes(p.name))
  const [{searchQuery}] = useQueryStates(searchParamsSearch)

  const { resource } = useContentFromRoute<DrupalNode>()
  if (productFilters.length == 0) {
    productFilters.push(...(resource?.field_products ?? resource?.field_product ? [resource.field_product] : []))
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = searchQuery ?? ''

      if (searchQuery !== null) {
        inputRef.current.focus()
      }
    }
  }, [searchQuery])

  // Identify when scrolling induces the sticky header behaviour.
  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]:IntersectionObserverEntry[]) => {
      const element = document.getElementById(stickyId)
      if (element) {
        element.dataset.sticky = entry.intersectionRatio < 1 ? 'true' : 'false'
      }
    }, {
        rootMargin: "100px 0px 0px 0px",
        threshold: [1],
    })

    const element = document.getElementById(observerMarkId)

    if (element) {
      observer.current.observe(element)
      return () => observer.current?.disconnect()
    }
  }, [stickyId, observerMarkId])

  useEffect(() => {
    const onKeyUp = (e: KeyboardEvent) => {
        // Toggle draw on Ctrl + "/"
        if (e.key == "/" && e.ctrlKey) {
          !isVisible ? showSearchUi() : hideSearchUi()
        }
    }
    document.addEventListener("keyup", onKeyUp);
    return () => {
        document.removeEventListener("keyup", onKeyUp);
    }
  })

  function onInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key !== 'Enter') {
      return
    }
    const query = (e.target as HTMLInputElement).value ?? ''
    if (query.length == 0) {
      return
    }
    runSearch(query, current?.products ?? null, 'searchInput')
  }

  function checkForSearchClear(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.value == "") {
      runSearch(null, current?.products ?? null)
    }
  }

  const searchOptions: {
    name: searchType
    title: string
  }[] = [
    {name: 'search', title: 'Search'},
    {name: 'aisearch', title: 'Pre-trained AI'}
  ]

  return (
    <div className={classNames("absolute bg-white min-h-screen xl:static lg:right-0", isVisible ? "shadow-2xl" : "")}>  
        <div className={classNames(isVisible ? "w-screen lg:w-[33dvw]" : "w-0", "transition-all ease-in-out duration-300 overflow-hidden")}>
          <div className="pb-6 relative max-h-screen h-full xl:fixed overflow-y-auto lg:w-[33dvw]">
          <div id={stickyId} className="sticky z-50 group/sticky data-[sticky=true]:shadow-lg top-0 bg-white pt-6 pb-2 px-8 transition-all duration-300">
            <div className="flex flex-row mb-2">
              <div className="flex-grow flex items-center gap-2">
                <Droplet className="w-8" />
                <h2 className="text-xl font-display font-bold mb-0 mt-1 flex-grow flex">
                  Acquia Help
                  {helpEngine == "aisearch" && <AiSvg className="w-8" />}
                </h2>
              </div>
              
              {accessConfiguration && (
                <>
                  <div>
                    <Menu as="div" className="relative inline-block text-left z-30">
                      <Menu.Button className='rounded p-2 hover:bg-navy-600/10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75'><Cog6ToothIcon className="w-[24px]" /></Menu.Button>
                      <Menu.Items 
                        className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-navy-300 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
                      >
                        {searchOptions.map(opt => (
                          <Menu.Item key={opt.name}>
                            {({ active }) => (
                              <button onClick={() => setHelpEngine(opt.name)} className={classNames(active ? 'bg-navy-600 text-white' : '', "group flex w-full items-center px-2 py-2 text-sm")}>
                              {helpEngine == opt.name ? (
                                <CheckIcon className="w-4 pr-1" />
                              ):(
                                <span className="w-4 pr-1"></span>
                              )}
                                {opt.title}
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Menu>
                  </div>
                </>
              )}
              <div className="self-start"><button aria-label="close" onClick={hideSearchUi} className="rounded p-2 hover:bg-navy-600/10 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"><XMarkIcon className="w-[24px]" /></button></div>
            </div>
            
            {helpEngine == 'search' && (
              <ProductFilters />
            )}

            <div className="my-4">
              <input 
                onKeyDown={onInputKeyDown}
                onChange={checkForSearchClear}
                ref={inputRef}
                autoComplete='off'
                placeholder="Ask a question. Tell us what you're looking for..."
                className="rounded border border-gray-600 p-2 w-full placeholder:text-gray-700"
                />
            </div>
          </div>
            <div id={observerMarkId} />
            <div className="px-8">
            {!current?.searchPhrase ? (
              <>
                <SearchQuestions />
              </>
            ) : (
              <>
                <SearchResults searchType={helpEngine} />
                {/* <SearchHelp isWorking={false} hasResults={true} /> */}
              </>
              
            )}
            
            </div>
          </div>
        </div>
    </div>
  )
}
