import * as React from "react";
import type { SVGProps } from "react";
const SvgSupportWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1.333c-3.535 0-6.4 2.388-6.4 5.334v.666h1.6c1.325 0 2.4.896 2.4 2v2c0 .555-.27 1.057-.708 1.42.107.17.298.408.59.639.317.252.71.46 1.172.553.285-.368.781-.612 1.346-.612.884 0 1.6.597 1.6 1.334C9.6 15.403 8.884 16 8 16c-.612 0-1.143-.286-1.413-.707a4.5 4.5 0 0 1-2.208-.935 4.009 4.009 0 0 1-.934-1.035c-.08.007-.162.01-.245.01h-.8c-1.325 0-2.4-.895-2.4-2V6.667C0 2.985 3.582 0 8 0s8 2.985 8 6.667v4.666c0 1.105-1.075 2-2.4 2h-.8c-1.325 0-2.4-.895-2.4-2v-2c0-1.104 1.075-2 2.4-2h1.6v-.666c0-2.946-2.865-5.334-6.4-5.334m-6.4 9.334v-2h1.6c.442 0 .8.298.8.666v2c0 .368-.358.667-.8.667h-.8c-.442 0-.8-.299-.8-.667zm11.2-2h1.6v2.666c0 .368-.358.667-.8.667h-.8c-.442 0-.8-.299-.8-.667v-2c0-.368.358-.666.8-.666"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgSupportWhite;
